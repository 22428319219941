<template>
    <page-content :title="topicId>0?'帖子管理':''">
        <div class="topic-item" v-if="topicId>0">
            <a-avatar style="margin-right: 10px" shape="square" :size="80" :src="topic.logo">{{topic.name}}
            </a-avatar>
            <div style="height: 80px">
                <div class="name">{{topic.name}}</div>
                <div class="des">{{topic.des}}</div>
            </div>

        </div>

        <common-table
                ref="table"
                path="web/app-forum"
                :query-params="queryParams"
                :columns="columns">

            <template slot="search">
                <a-button v-if="topicId>0" ghost type="primary" @click="add">新增</a-button>
                <h3 v-else>用户帖子管理</h3>
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="35" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
            </template>

            <template slot="status" slot-scope="{record}">
<!--                <a-switch :checked="record.disable" size="small" @click="clickDisable(record)"></a-switch>-->

                <a-dropdown>
                    <a-menu slot="overlay" @click="changeStatus($event,record)" :trigger="['click']">
                        <a-menu-item key="0">待审核</a-menu-item>
                        <a-menu-item key="1">已发布</a-menu-item>
                        <a-menu-item key="2">已屏蔽</a-menu-item>
                    </a-menu>
                    <a-button v-if="record.status==1" size="small" >已发布<a-icon type="down" /> </a-button>
                    <a-button v-else-if="record.status==2" type="dashed" size="small" >已屏蔽<a-icon type="down" /> </a-button>
                    <a-button v-else size="small" type="primary" ghost>待审核<a-icon type="down" /> </a-button>
                </a-dropdown>

            </template>

            <template slot="operation" slot-scope="{record}">
                <action-message @click="viewComment(record)"></action-message>
                <!--        <action-edit  @click="edit(record)"></action-edit>-->
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <forum-post-edit ref="editDrawer" @success="getList"></forum-post-edit>
    </page-content>

</template>
<script>
    import ForumPostEdit from './ForumPostEdit'

    export default {
        components: {
            ForumPostEdit
        },
        data() {
            return {
                topicId: 0,
                queryParams: {
                    topicId: this.$route.query.topicId || 0
                },
                topic: {}
            }
        },

        created() {
            this.topicId = this.$route.query.topicId || 0

            if (this.topicId > 0) {
                this.$get("web/app-forum-topic/" + this.topicId).then(suc => {
                    this.topic = suc.data
                })
            }
        },

        computed: {
            columns() {
                return [
                    {
                        title: '',
                        dataIndex: 'avatarUrl',
                        scopedSlots: {customRender: 'logo'},
                        width: 80
                    },
                    {
                        title: '发布者',
                        dataIndex: 'nickName'
                    },
                    {
                        title: '标题',
                        dataIndex: 'title',
                        ellipsis: true
                    },
                    {
                        title: '内容',
                        dataIndex: 'content',
                        ellipsis: true
                    },
                    // {
                    //   title: '分享',
                    //   dataIndex: 'share'
                    // },
                    // {
                    //   title: '回复',
                    //   dataIndex: 'reply'
                    // },
                    // {
                    //   title: '点赞',
                    //   dataIndex: 'like'
                    // },
                    {
                        title: '发布时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'status'},
                        filters: [
                            {text: '全部', value: ""},
                            {text: '待审核', value: '0'},
                            {text: '已发布', value: '1'},
                            {text: '已屏蔽', value: '2'}
                        ],
                        filterMultiple: false,
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 80,
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add() {
                this.$refs.editDrawer.show({topicId: this.topicId})
            },
            edit(record) {
                this.$refs.editDrawer.show(record)
            },
            changeStatus(e,record){
                this.$put('web/app-forum', {
                    id: record.id,
                    status: e.key
                }).then(r => {
                    this.getList()
                })
            },

            clickDisable(record) {
                this.$put('web/app-forum', {
                    id: record.id,
                    disable: !record.disable
                }).then(r => {
                    this.getList()
                })
            },
            remove(record) {
                var that = this
                this.$confirm({
                    title: '是否删除该帖子?',
                    content: '将同时该帖子的评论和点赞记录',
                    centered: true,
                    onOk() {
                        that.$delete('web/app-forum/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },
            viewComment(record) {
                let id = record.id
                this.$router.push(`/forum/post/${id}/reply`)
            },

            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

    .topic-item {
        border: 1px solid #F0F0F0;
        padding: 10px;
        display: flex;
        align-items: center;

        .name {
            margin-bottom: 2px;
            font-weight: 500;
        }

        .des {
            color: #999999;
        }
    }
</style>
